<template>
  <div class="container">
    <a-page-header :title="type == 1 ? '项目标签' : '服务商标签'" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-button type="primary" @click="handleEditLabel" style="margin-bottom:10px;">添加</a-button>
      <a-table
        :columns="columns"
        row-key="id"
        :pagination="false"
        :data-source="lableData">
          <template slot="operate" slot-scope="text, item">
            <a-button size="small" type="primary" @click="handleEditLabel(item)">编辑</a-button>
            &nbsp;
            <a-popconfirm title="确定要删除吗？" placement="bottomRight" ok-text="确定" cancel-text="取消" @confirm="handleDeleteLabel(item)">
              <a-button type="danger" icon="reload" size="small">删除</a-button>
            </a-popconfirm>
          </template>
      </a-table>
      <a-modal v-model="showLableModal" title="标签设置" :footer="null" @cancel="hideLableModel">
        <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form">
          <a-form-item label="">
            <a-input
                type="hidden"
                v-decorator="['id', {rules: []}]"
              />
          </a-form-item>
          <a-form-item label="名称">
            <a-input
              type="text"
              v-decorator="['name', {rules: [{required: true, message: '请输入名称'}]}]"
            />
          </a-form-item>
          <a-form-item label=" " :colon="false">
            <a-button size="large" type="primary" @click="submitLableModel">确定</a-button>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { lableData } from '@/utils/api'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '标签名称',
    dataIndex: 'name'
  },
  {
    title: '操作',
    dataIndex: 'operate',
    scopedSlots: { customRender: 'operate' },
  }
]
export default {
  name: 'LableList',
  data() {
    return {
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this),
      columns,
      lableData: [],
      showLableModal: false,
      type: 0
    }
  },
  created() {
    this.type = this.$route.params.type
    this.getLableData()
  },
  watch: {
    $route: {
      handler: function(){
        this.type = this.$route.params.type
        this.getLableData()
      }
    }
  },
  methods: {
    getLableData () {
      lableData({
        type: this.type,
        switch: 4
      }).then((res) => {
        this.lableData = res.info
      })
    },
    handleEditLabel (item={}) {
      this.showLableModal = true
      this.form.getFieldDecorator('id', {initialValue: item.id || ''})
      this.form.getFieldDecorator('name', {initialValue: item.name || ''})
    },
    handleDeleteLabel (item) {
      lableData({
        switch: 3,
        id: item.id
      }).then(() => {
        const _this = this
        this.$success({
          title: '提示',
          content: '删除成功',
          okText: '确定',
          onOk() {
            _this.hideLableModel()
            _this.getLableData()
          }
        })
      })
    },
    hideLableModel () {
      this.showLableModal = false
    },
    submitLableModel () {
      this.form.validateFields((err, values) => {
        if (!err) {
          values.type = this.type
          if (values.id) {
            values.switch = 2
          } else {
            values.switch = 1
          }
          lableData(values).then(() => {
            const _this = this
            this.$success({
              title: '提示',
              content: '操作成功',
              okText: '确定',
              onOk() {
                _this.hideLableModel()
                _this.getLableData()
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
</style>